import React from 'react';
import cx from 'classnames';

export default ({children: player, activePlayer, setActivePlayer}) => {
    const className = cx(
        'Player',
        {
            'Player--highlight': activePlayer === player
        }
    );
    return (
        <li
            onMouseEnter={() => setActivePlayer(player)}
            onMouseLeave={() => setActivePlayer(null)}
            className={className}
        >
            {player}
        </li>
    );
};