import React, { useState } from 'react';
import data from './data';
import Player from './Player';
import aegis from './images/aegis.png';
import './index.css';

export default () => {
    const [activePlayer, setActivePlayer] = useState(null);

    return (
        <div className="HallOfFame">
            {data.map((entry, index) => (
                <div key={index} className="Entry">
                    <img src={entry.image || aegis} alt={entry.name} className="Entry__Image" />
                    <a className="Entry__Title" href={entry.brackets}>{entry.name}</a>
                    <ul className="Entry__Teams">
                        {entry.rankings.map((ranking) => (
                            <li>
                                {ranking.name}
                                <ul className="Entry__Players">
                                    {ranking.players.map((player) => (
                                        <Player activePlayer={activePlayer} setActivePlayer={setActivePlayer}>{player}</Player>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}