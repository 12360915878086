import dotacup1 from './images/dotacup1.jpg';
import dotacup2 from './images/dotacup2.jpg';
import dotacup4 from './images/dotacup4.png';
import onevoneturna from './images/1v1turna.jpg';
import turniir1 from './images/turniir1.jpg';
import lan1 from './images/lan1.png';
import lan2 from './images/lan2.png';
import kassid30 from './images/kassid30.jpeg';
import rog1 from './images/rog1.png';
import cee1 from './images/cee_dota.png';

export default [
    {
        name: 'Dota.ee #1',
        image: dotacup1,
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21212152',
        rankings: [
            {
                name: 'meow',
                players: [
                    'PusH', 'Akuma', 'Timpz', 'Timu', 'Ircute'
                ]
            },
            {
                name: 'scenic',
                players: [
                    'Dekamano', 'Mikk', 'Freakz', 'Erki', 'Cerb'
                ]
            },
            {
                name: 'DeadorAlive - AlcaBB/PznEntry',
                players: []
            }
        ]
    },
    {
        name: 'Dota.ee #2',
        image: dotacup2,
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21301050',
        rankings: [
            {
                name: 'suema',
                players: [
                    'dasset', 'Donka', 'lempsukiisu', 'Fleim', 'Prisoner'
                ]
            },
            {
                name: 'Team ApeRanus',
                players: [
                    'DangerZ', 'Zapple', 'Abith', 'N2xy', 'Luza', 'Rakett'
                ]
            },
            {
                name: 'scenic',
                players: [
                    'Dekamano', 'Freakz', 'Mikk', 'Erki', 'Cerb'
                ]
            },
            {
                name: 'meow',
                players: [
                    'PusH', 'Timu', 'DaddyDj', 'Ice', 'Timpz'
                ]
            }
        ]
    },
    {
        name: '1v1 Turniir nr.1',
        image: onevoneturna,
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21302142',
        rankings: [
            {
                name: 'Push',
                players: [
                    'Push'
                ]
            },
            {
                name: 'Fleim',
                players: [
                    'Fleim'
                ]
            },
            {
                name: 'Mikk',
                players: [
                    'Mikk'
                ]
            }
        ]
    },
    {
        name: 'Turniir #1',
        image: turniir1,
        description: '',
        rankings: [
            {
                name: 'meow',
                players: [
                    'PusH', 'Ice', 'Akuma', 'Timu', 'Timpz', 'DaddyDj'
                ]
            },
            {
                name: 'VideoGamers.eu',
                players: [
                    'DangerZ', 'Freakz', 'Zapple', 'N2xy', 'Rakett'
                ]
            },
            {
                name: 'Steamrollers',
                players: [
                    'Strom', 'Foux', 'WANKmaster', 'pzn', 'Cookiemonster', 'ToKe'
                ]
            }
        ]
    },
    {
        name: 'TTÜ Dota 2 LAN',
        image: lan1,
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21302220',
        rankings: [
            {
                name: 'idle.ee',
                players: [
                    'dasset', 'lempsukiisu', 'Mikk', 'Foux', 'Cerb'
                ]
            },
            {
                name: 'VideoGamers.eu',
                players: [
                    'DangerZ', 'Freakz', 'Zapple', 'N2xy', 'Rakett'
                ]
            },
            {
                name: 'L0L 4 L1F3',
                players: [
                    'Qufi', 'Soulyah', 'Creotic', 'kris', 'H'
                ]
            }
        ]
    },
    {
        name: 'Dota.ee #3',
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21303302',
        rankings: [
            {
                name: 'idle.ee',
                players: [
                    'PusH', 'DaddyDj', 'Ice', 'Timpz', 'Timu', 'Akuma'
                ]
            },
            {
                name: 'Steamrollers',
                players: [
                    'BUGABUGA', 'Rakett', 'liewec', 'pzn', 'fakedeath', 'šeid'
                ]
            },
            {
                name: 'No Fear Tactics',
                players: [
                    'Soulyah', 'Hents', 'Deemzul', 'Martin', 'Creotic'
                ]
            }
        ]
    },
    {
        name: 'TTÜ eSport 2013 Suvi',
        image: lan1,
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21308140',
        rankings: [
            {
                name: 'nGu',
                players: [
                    'PusH', 'aL!Ve', 'Ice', 'Akuma', 'Timu'
                ]
            },
            {
                name: 'druidz',
                players: [
                    'DangerZ', 'Freakz', 'Mikk', 'Grasz', 'Zapple'
                ]
            },
            {
                name: 'WI3',
                players: [
                    'BOWNED', 'Ingman', 'Jeeps', 'Threw', 'Walcker'
                ]
            }
        ]
    },
    {
        name: 'Dota.ee #4',
        image: dotacup4,
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21309011',
        rankings: [
            {
                name: 'suema',
                players: [
                    'Fleim', 'Donka', 'Deansy', 'H', 'lempsukiisu', 'Prisoner'
                ]
            },
            {
                name: 'druidz',
                players: [
                    'Zapple', 'Freakz', 'DangerZ', 'Grasz', 'Mikk', 'N2xy'
                ]
            },
            {
                name: 'nGu',
                players: [
                    'PusH', 'DaddyDj', 'Timu', 'Ice', 'Akuma', 'aL!Ve'
                ]
            }
        ]
    },
    {
        name: '1v1 Turniir nr.2',
        description: '',
        rankings: [
            {
                name: 'Push',
                players: [
                    'Push'
                ]
            }
        ]
    },
    {
        name: 'TTÜ eSport 2013 Sügis',
        image: lan1,
        description: '',
        brackets: 'http://binarybeast.com/xDOTA213112210',
        rankings: [
            {
                name: 'Puupakud',
                players: [
                    'Freakz', 'Naxy', 'Grasz', 'bra', 'dekamano'
                ]
            },
            {
                name: 'ELDA',
                players: [
                    'Cerb', 'Mikk', 'Lahe', 'Abith', 'Zapple'
                ]
            },
            {
                name: 'suema',
                players: [
                    'dasset', 'Qufi', 'Foux', 'd0rk', 'Nibblet'
                ]
            }
        ]
    },
    {
        name: 'Dota.ee #5',
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21312147',
        rankings: [
            {
                name: 'sTg',
                players: [
                    'Freakz', 'Grasz', 'Naxy', 'DangerZ', 'deva-deva'
                ]
            },
            {
                name: 'ELDA',
                players: [
                    'Mikk', 'Abith', 'Cerb', 'Zapple', 'Lahe'
                ]
            },
            {
                name: 'nGu',
                players: [
                    'PusH', 'DaddyDj', 'Timu', 'Ice', 'Akuma', 'aL!Ve'
                ]
            }
        ]
    },
    {
        name: 'TTÜ e-Sport Kevad 2014',
        image: lan2,
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21403283',
        rankings: [
            {
                name: 'sTg',
                players: [
                    'Freakz', 'DangerZ', 'Naxy', 'Grasz', 'Notah'
                ]
            },
            {
                name: 'nGu',
                players: [
                    'Akuma', 'aL!Ve', 'PusH', 'Ice', 'DaddyDj'
                ]
            },
            {
                name: 'Viniest Vinculum',
                players: [
                    'Y009', 'Usa and I', 'Feliks', 'Rauno', 'The RedSnow'
                ]
            }
        ]
    },
    {
        name: 'Dota.ee Cup #6',
        description: '',
        brackets: 'http://binarybeast.com/xDOTA21406281',
        rankings: [
            {
                name: 'Estraverse Gaming',
                players: [
                    'Usa and I', 'The Redsnow', 'Y009', 'Qufi', 'Ice'
                ]
            },
            {
                name: 'Team Mullet',
                players: [
                    'Yazzu', 'By Fire Be Purged', 'Fez', 'DoktorLove', 'DangerZ'
                ]
            },
            {
                name: 'Koit',
                players: [
                    '4money', 'glorious pc masterace', 'bsoul', 'zonjaboss', 'The tsäädsä'
                ]
            },
            {
                name: 'Numinous Gaming',
                players: [
                    'Alex', 'Waifurunner', 'Employis', 'Honeyface', 'Bubbles'
                ]
            }
        ]
    },
    {
        name: 'TTÜ e-Sport Sügis 2016',
        image: lan1,
        description: '',
        brackets: 'https://www.toornament.com/en_US/tournaments/435955482487489856/stages/435955483024361007/',
        rankings: [
            {
                name: 'sTg',
                players: [
                    'Mikk', 'Cerb', 'Grasz', 'Usa and I', 'Kunn'
                ]
            },
            {
                name: '4Gamers',
                players: [
                    'Hernst', 'Volimur', 'AdmiralComrade', 'drus', 'Estonian'
                ]
            },
            {
                name: 'goexanimo.lv',
                players: [
                    'Feggit', 'Ulafzs', 'Stepileemur', 'Erkki', 'Miguel'
                ]
            }
        ]
    },
    {
        name: 'Eesti Dota Turniir 2020',
        description: '',
        brackets: 'https://www.toornament.com/en_GB/tournaments/3202058019890315264/stages/3202063908884094976/',
        rankings: [
            {
                name: 'Resistance',
                players: [
                    'Blackberry', 'Yn', 'ankou', 'xn\'e', 'Estonian'
                ]
            },
            {
                name: 'ei ütle',
                players: [
                    'inx4c', 'severe', 'Mikk', 'Shift', 'Nozomi'
                ]
            },
            {
                name: 'Maet Anul',
                players: [
                    'Mento', 'Mustus', 'Muhv', 'IodineVanadiumOxygen', 'Stepileemur'
                ]
            }
        ]
    },
    {
        name: 'KASSID-30',
        description: '',
        image: kassid30,
        brackets: 'https://www.toornament.com/en_GB/tournaments/3403126254087651328/stages/3403127955109060608/',
        rankings: [
            {
                name: 'Herne DreamTeam',
                players: [
                    'Hernst', 'Rab8', 'Grasz', 'KORO', 'Nozomi'
                ]
            },
            {
                name: 'Maet Anul',
                players: [
                    'Mento', 'Senshi', 'IodineVanadiumOxygen', 'Mustus', 'Stepileemur'
                ]
            },
            {
                name: '5 Ankrut, no robo',
                players: [
                    'Juan€', 'KRIFFF', 'Madisste', 'A.Itäh', 'Grac-'
                ]
            }
        ]
    },
    {
        name: '2021 Esimene Dota 2 turniir',
        description: '',
        brackets: 'https://www.toornament.com/en_GB/tournaments/4259771032464375808/stages/4259773751342710784/',
        rankings: [
            {
                name: 'PepegaClappers vol. 1',
                players: [
                    'Hernst', 'Fespixx', 'IodineVanadiumOxygen', 'Mento', 'Grasz'
                ]
            },
            {
                name: 'Kassi DreamTeam',
                players: [
                    'Hisonerd', 'Rab8', 'Nozomi', '-AL-', 'Mõmmi #PandaMadu'
                ]
            },
            {
                name: 'Meow',
                players: [
                    'Prisoner', 'aL!ve', 'Ice', 'Soulja', 'PusH'
                ]
            }
        ]
    },
    {
        name: 'Estonian Quarantine Games: Dota 2 (2021)',
        description: '',
        brackets: 'https://e-sports.ee/events/estonian-quarantine-games/#top',
        rankings: [
            {
                name: 'After The Rain',
                players: [
                    'Nozomi', 'severe', 'Grasz', 'Fespixx', 'Rab8'
                ]
            },
        ]
    },
    {
        name: 'ROG Dota 2 Turniir',
        description: 'E-sport Meedia Grupp koostöös Dota.ee kogukonnaga korraldavad selle aasta esimese suure Eesti e-spordi turniiri videomängus Dota 2. Turniir leiab aset üle interneti 19. ja 20. veebruaril.',
        brackets: 'https://play.toornament.com/en_US/tournaments/5381889375613927424/',
        image: rog1,
        rankings: [
            {
                name: 'Team Juss Ants',
                players: [
                    'Nozomi', 'severe', 'Rab8', 'Grasz', 'Shift'
                ]
            },
            {
                name: '2846 kahju 27,5 sekundiga',
                players: [
                    'IodineVanadiumOxygen', 'Mustus', 'inx4c', 'SenShi', 'Fespixx'
                ]
            },
            {
                name: 'Resistance',
                players: [
                    'Grac-', 'xn\'e', 'Blackberry', 'Arion', 'ankou', 'Toksagodz~'
                ]
            },
        ]
    },
    {
        name: 'IESF: Eesti Kvalifikatsioon (2022)',
        description: '',
        brackets: 'https://e-sports.ee/dota-2-eesti-kvalifikatsioon/',
        rankings: [
            {
                name: 'Team Juss Ants',
                players: [
                    'Rab8', 'xn\'e', 'ankou', 'Nozomi', 'severe'
                ]
            },
            {
                name: 'BattleCrebs',
                players: [
                    'Senshi', 'Grasz', 'Fespixx', 'IodineVanadiumOxygen', 't2t4'
                ]
            },
            {
                name: 'Ded',
                players: [
                    'Arion', 'vikitan', 'chesvik', 'Lev', 'pxvxl'
                ]
            },
        ]
    },
    {
        name: 'CEE Champions: DOTA 2 Eesti kvalifikatsioon',
        description: `CEE Champions tuleb taas, seekord teises mängus!

Kesk- ja Ida-Euroopa «DOTA 2» Meistrivõistlused on regiooni suurim «DOTA 2» turniir, miles 18. riigi esindajad selgitavad välja parima. Esimese hooaja auhinnafondiks on 10 000 dollarit. Regiooni 32 parimat võistkonda saavad võimaluse panna end proovile rahusvahelisel virtuaalsel areenil, et selgitada osavaim Kesk- ja Ida-Euroopa «DOTA 2» tiim.

CEE Champions DOTA 2 Eesti kvalifikatsioonid leiavad aset 10. ja 11. septembril. Eestist pääseb CEE Championsi põhiturniirile KAKS PARIMAT võistkond!

CEE Champions põhiturniirist saab lugeda: https://ceechampions.com/dota2/`,
        brackets: 'https://play.toornament.com/en_US/tournaments/5993381188209524736/',
        image: cee1,
        rankings: [
            {
                name: 'Koro Dream Team',
                players: [
                    'Rab8', 'Grasz', 'Kolaeila', 'Shift', 'Nozomi'
                ]
            },
            {
                name: 'Thinking Face',
                players: [
                    'Arion', 'inx4c', 'Fespixx', 'IodineVanadiumOxygen', 'Mustus'
                ]
            },
            {
                name: 'Ursa Minor',
                players: [
                    'Mikk', 'Usa and I', 'Cykawarrior', 'Muhv', 'KORO'
                ]
            },
        ]
    },
].reverse();
