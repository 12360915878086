import React from 'react';
import {
  Switch,
  Route,
  withRouter,
  Link
} from "react-router-dom";
import cx from 'classnames';
import HOF from './HallOfFame';
import './Main.css';
import logo from './logo.svg';
import mlem from './mlem.png';
import twitch from './twitch.png';
import discord from './discord.png';

export default withRouter(({location}) => {
	const isFrontPage = location.pathname === '/';

	return (
		<div
			className={cx(
				'App',
				{'App--front': isFrontPage}
			)}
		>
			<Link to="/">
				<img
					src={logo}
					alt="logo"
					className="App-logo"
				/>
			</Link>
			<Switch>
				<Route path="/hof">
					<HOF />
				</Route>
				<Route path="/">
					<div className="MainLinks">
						<Link to="/hof">Hall of Fame</Link>
						<a href="https://www.twitch.tv/eestidota">Twitch</a>
						<a href="https://discord.gg/rvst3tc">Discord</a>
					</div>
				</Route>
			</Switch>
			<div className="cool-links">
				<a href="https://www.twitch.tv/eestidota">
					<img src={twitch} alt="Eesti Dota Twitchi kanal" />
				</a>
				<a href="https://discord.gg/rvst3tc">
					<img src={discord} alt="Eesti Dota Discordi server" />
				</a>
			</div>
		</div>
	);
})